.root {
	overflow: hidden;

	.video {
		z-index: 2;
		position: relative;
		object-fit: contain;
		height: 100%;
	}

	.threshold-container {
		display: flex;
		flex-direction: column;
		position: absolute;
		justify-content: center;
		align-items: center;
		gap: 10px;
		top: 100px;
		right: 16px;
		z-index: 5;
		background-color: white;
		border-radius: 10px;
		padding: 5px;
	}

	.take-photo-button-container {
		position: absolute;
		bottom: 100px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 5;

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		.take-photo-button {
			padding: 0;
			background-color: transparent;
			border: none;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 80px; /* Outer circle */
			height: 80px;
			border-radius: 50%;
			position: relative;
			cursor: pointer;
			transition: transform 0.3s ease-in-out;
		}

		.take-photo-button:hover {
			transform: scale(0.9);
		}

		.inner-take-photo-button {
			width: 70px; /* Adjusted for inner circle */
			height: 70px;
			border-radius: 50%;
			background-color: white;
			border: 4px solid rgba(255, 255, 255, 0.6);
			transition: width 0.3s ease, height 0.3s ease; /* Add smooth shrinking effect */
			position: relative;
			z-index: 2; /* Ensure it stays above the loader */
		}

		.take-photo-button.clicked .inner-take-photo-button {
			width: 60px; /* Reduced size on click */
			height: 60px; /* Reduced size on click */
		}

		.take-photo-button::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 4px solid rgba(255, 255, 255, 0.8); /* Outer circle */
		}
	}

	.loader-container {
		position: absolute;
		bottom: 100px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 4;

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	.loader {
		position: relative;
		width: 80px;
		height: 80px;
	}

	.circle {
		fill: none;
		stroke: red;
		stroke-width: 2.8;
		stroke-dasharray: 100, 100; /* Set up for full circle */
		stroke-dashoffset: 100; /* Start fully "unfilled" */
	}
	
	@keyframes dashAnimation {
		0% {
			stroke-dashoffset: 100; /* Start unfilled */
		}
		99% {
			stroke-dashoffset: 0; /* Animate dash offset to 0 */
		}
		100% {
			stroke-dashoffset: 100; /* Instantly reset to unfilled */
		}
	}
	
	.circle.animate {
		animation: dashAnimation 3s ease forwards; /* Apply animation for 3s */
	}

	// .circle {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	border-radius: 50%;
	// 	background: conic-gradient(red 0%, transparent 0%);
	// 	// animation: spin 2s linear infinite;
	// 	z-index: 2;
	// }

	// .circle.animate {
	// 	animation: fill-clockwise 3s linear forwards;
	// }

	// @keyframes fill-clockwise {
	// 	0% {
	// 		background: conic-gradient(red 0deg, transparent 0deg);
	// 	}
	// 	100% {
	// 		background: conic-gradient(red 360deg, transparent 0deg);
	// 	}
	// }

	.canvas {
		display: none;
	}

	.picture-blurry {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: white;
		font-size: 24px;
		background-color: white;
		padding: 10px;
		border-radius: 10px;
		z-index: 4;
	}

	.cross-container {
		position: fixed;
		z-index: 4;
		top: 16px;
		left: 16px;
	}

	.image {
		width: 100%;
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		object-fit: cover;
	}
}
